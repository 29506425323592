@import "../variables";
@import "../mixins";
@import "../sprites";

#section-banner-countdown {
    width: 100%;
    float: left;
    overflow: hidden;
    margin-top: 0;
    border-top: 144px solid white;

    @media screen and (max-width: 767px) {
        margin-top: 0;
        border-top: 60px solid white;
    }

    .col-minibanner {
        @media screen and (max-width: 767px) {
            padding-top: 1.3rem;
            padding-bottom: 1.3rem;
        }
    }
}

#section-banner-principal {
    width: 100%;
    float: left;
    overflow: hidden;
    margin-top: 91px;

    &.contador-regressivo-ativo {
        margin-top: 0px;
    }

    .slick-arrow {
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        width: 100px;
        height: 100%;
        top: 0px;
        z-index: 98;
        border: 0px;
        text-indent: -9999px;
        outline: none;

        &.slick-prev {
            background-image: url(../img/icones/seta-prev.png);
            left: 0px;
            background-position: 50% 60%;
            @media screen and (max-width: 767px) {
                left: -10px;
                background-size: 20% auto;
                opacity: 0.9;
            }
        }

        &.slick-next {
            background-image: url(../img/icones/seta-next.png);
            right: 0px;
            background-position: 50% 60%;
            @media screen and (max-width: 767px) {
                right: -10px;
                background-size: 20% auto;
                opacity: 0.9;
            }
        }
    }

    @media (max-width: 992px) {
        margin-top: 163px;
    }

    @media (max-width: 767px) {
        margin-top: 60px;
    }
    .reg-banner-principal {
        background-position: center;
        background-size: cover;
        width: 100%;
        float: left;
        overflow: hidden;

        .mascara {
            width: 100%;
            float: left;
            overflow: hidden;
            height: 676px;

            @media (max-width: 1366px) {
                height: 570px;
            }

            @media (max-width: 1280px) {
                height: 519px;
            }

            @media (max-width: 1170px) {
                height: 390px;
            }

            @media (max-width: 767px) {
                height: unset;
                height: 517px;
            }

            .top {
                width: 100%;
                float: left;
                padding-top: 87px;
                overflow: hidden;

                @media (max-width: 1200px) {
                    padding-top: 100px;
                }

                @media (max-width: 992px) {
                    padding-top: 70px;
                }

                @media (max-width: 767px) {
                    padding-top: 82px;
                }

                @media (max-width: 350px) {
                    padding-top: 42px;
                }

                h1 {
                    width: 100%;
                    float: left;
                    color: #fff;
                    font-size: 55px;
                    line-height: 65px;

                    @media (max-width: 1200px) {
                        text-align: center;
                    }

                    @media (max-width: 992px) {
                        font-size: 48px;
                        line-height: 62px;
                    }

                    @media (max-width: 767px) {
                        font-size: 32px;
                        line-height: 40px;
                    }

                    @media (max-width: 350px) {
                        font-size: 27px;
                        line-height: 34px;
                    }
                }

                .descricao {
                    width: 100%;
                    float: left;
                    padding-left: 40px;
                    padding-top: 30px;

                    @media (max-width: 1200px) {
                        display: none;
                    }

                    p {
                        color: #fff;
                        font-size: 20px;
                    }
                }
            }

            .bottom {
                width: 100%;
                float: left;
                padding-top: 40px;
                overflow: hidden;

                @media (max-width: 1200px) {
                    padding-top: 20px;
                }

                @media (max-width: 767px) {
                    padding-top: 0px;
                }

                .info {
                    float: left;
                    color: #fff;
                    padding-top: 40px;
                    font-size: 20px;

                    @media (max-width: 1200px) {
                        display: none;
                    }
                }

                .img {
                    border: 4px solid #fff;
                    padding: 5px;
                    width: 232px;
                    display: block;
                    overflow: hidden;
                    margin: 0px auto;
                    border-radius: 50%;

                    @media (max-width: 1200px) {
                        display: none;
                    }

                    img {
                        float: left;
                        border-radius: 50%;
                    }
                }

                .box-valor {
                    width: 100%;
                    float: left;
                    margin-top: 19px;
                    margin-bottom: 10px;
                    clear: both;
                    overflow: hidden;

                    span {
                        width: 100%;
                        float: left;
                        overflow: hidden;
                        font-size: 35px;
                        color: #fff;
                        text-align: center;

                        b {
                            font-size: 53px;
                            font-weight: bold;

                            @media (max-width: 767px) {
                                font-size: 35px;
                                line-height: 30px;
                            }
                        }
                    }
                }

                .botao {
                    background-color: #258d35;
                    width: -webkit-fit-content;
                    width: -moz-fit-content;
                    width: fit-content;
                    height: 80px;
                    display: block;
                    padding-left: 30px;
                    padding-right: 30px;
                    margin: 0px auto;
                    overflow: hidden;
                    border-radius: 40px;
                    -webkit-transition: all 0.4s;
                    -o-transition: all 0.4s;
                    transition: all 0.4s;

                    @media (max-width: 767px) {
                        height: 65px;
                    }

                    &:hover {
                        background-color: #2fb644;

                        img {
                            -webkit-transform: rotate(90deg);
                            -ms-transform: rotate(90deg);
                            transform: rotate(90deg);
                        }
                    }

                    span {
                        float: left;
                        padding: 19px 0px 0px 0px;
                        overflow: hidden;
                        font-size: 30px;
                        color: #fff;
                        font-weight: bold;

                        @media (max-width: 767px) {
                            padding: 15px 0px 0px 0px;
                            font-size: 23px;
                        }
                    }

                    img {
                        float: left;
                        margin: 19px 0px 0px 17px;
                        -webkit-transition: all 0.4s;
                        -o-transition: all 0.4s;
                        transition: all 0.4s;

                        @media (max-width: 767px) {
                            margin: 15px 0px 0px 13px;
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
    .reg-banner {
        background-position: center;
        background-size: cover;
        width: 100%;
        float: left;
        overflow: hidden;

      
        .mascara {
            background: rgba(16,44,229,0.55);
            background: -moz-linear-gradient(top, rgba(16,44,229,0.55) 0%, rgba(38,49,106,0.55) 71%, rgba(18,3,3,0.55) 100%);
            background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(16,44,229,0.55)), color-stop(71%, rgba(38,49,106,0.55)), color-stop(100%, rgba(18,3,3,0.55)));
            background: -webkit-linear-gradient(top, rgba(16,44,229,0.55) 0%, rgba(38,49,106,0.55) 71%, rgba(18,3,3,0.55) 100%);
            background: -o-linear-gradient(top, rgba(16,44,229,0.55) 0%, rgba(38,49,106,0.55) 71%, rgba(18,3,3,0.55) 100%);
            background: -ms-linear-gradient(top, rgba(16,44,229,0.55) 0%, rgba(38,49,106,0.55) 71%, rgba(18,3,3,0.55) 100%);
            background: linear-gradient(to bottom, rgba(16,44,229,0.55) 0%, rgba(38,49,106,0.55) 71%, rgba(18,3,3,0.55) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#102ce5', endColorstr='#120303', GradientType=0 );
            width: 100%;
            float: left;
            overflow: hidden;
            height: unset;

            @media (max-width: 1366px) {
                // height: 570px;
            }

            @media (max-width: 1200px) {
                // height: 519px;
            }

            @media (max-width: 767px) {
                // height: 514px;
                height: auto;
            }
        }

        .top-box{
            .box{
                width: 150px;
                height: 150px;
                position: relative;
                transform: rotate(-10deg);

                @media screen and (max-width:767px) {
                    width: 120px;
                    height: 120px;
                }

                &:before{
                    content: attr(data-text);
                    text-transform: uppercase;
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    right: -15px;
                    bottom: -15px;
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    -webkit-box-shadow: -5px -5px 5px 0px rgba(0,0,0,0.25);
                    -moz-box-shadow: -5px -5px 5px 0px rgba(0,0,0,0.25);
                    box-shadow: -5px -5px 5px 0px rgba(0,0,0,0.25);
                    overflow: hidden;
                    font-weight: bold;
                }
            }
            .box-1{
                background-color: #FFCE00;

                &:before{  
                    color: white;
                    background-color: #2C276B;
                    font-size: 1.2rem;
                    line-height: 1.2rem;

                    @media screen and (max-width:767px) {
                        font-size: 1rem;
                        line-height: 1rem;
                    }
                }
            }
            .box-2{               
                background-color: #2C276B;
               
                &:before{
                  
                    color: #2C276B;
                    background-color: white;
                    font-size: 1.7rem;
                    line-height: 1.7rem;

                    @media screen and (max-width:767px) {
                        font-size: 1rem;
                        line-height: 1rem;
                    }
                }
            }
        }

        .title-col{
            @media screen and (max-width:767px) {
                padding-top: 2rem;
                padding-bottom: 2rem;
            }
            h1{
                width: 100%;
                border-bottom: 12px solid white;

                @media screen and (min-width:1366px) {
                    font-size: 60px;
                }

                @media screen and (max-width:767px) {
                    border-bottom: 1px solid transparent;
                    position: relative;

                    &:after{
                        content: ' ';
                        position: absolute;
                        bottom: -0.25rem;
                        left: 10%;
                        right: 10%;
                        height: 1px;
                        background-color: white;
                    }
                }
            }
        }
        .price-col{
            .price-box{
                background-color: rgba(255, 206, 0, 0.87);
                border-radius: 5px;

                .lh-price{
                    line-height: 2.1rem;
                }

                .price-currency{
                    color: #272726;
                    font-size: 1.7rem;
                    font-weight: bold;

                    &.promo-cut{
                        font-size: 1.1rem;
                        position: relative;

                        &:before{
                            content: ' ';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            right: 0;
                            height: 1px;
                            background: #272726;
                        }
                    }
                }
                .prince-val{
                    color: #272726;
                    font-size: 2.8rem;
                    font-weight: bold;

                    &.promo-cut{
                        font-size: 1.1rem; 
                        position: relative;

                        &:before{
                            content: ' ';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            right: 0;
                            height: 1px;
                            background: #272726;
                        }
                    }
                }
                .price-obs{
                    color: #272726;
                    font-size: 0.9rem;
                }
                .buy-link{
                    outline: none;
                    font-size: 1.2rem;
                    color: white;
                    background-color:#42B8B3;
                    border: 1px solid #22807C;
                    display: block;
                    text-decoration: none;
                    transition: all 0.4s ease;
                    -webkit-box-shadow: -2.5px 2.5px 5px 0px rgba(0,0,0,0.15);
                    -moz-box-shadow: -2.5px 2.5px 5px 0px rgba(0,0,0,0.15);
                    box-shadow: -2.5px 2.5px 5px 0px rgba(0,0,0,0.15);
                    border-radius: 10px 25px 25px 10px;
                    position: relative;

                    .arrow-fancy{
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        width: 46px;
                        background-color: #5BDED9;
                        border-radius: 100%;
                        text-align: center;
                    }

                    &:hover{
                        background-color: darken($color: #42B8B3, $amount: 10);
                    }
                }
            }
        }
        .col-info{
            @media screen and(max-width:767px) {
                display: none;
            }
        }
    }
}

#section-banner-secundario{
    width: 100%;
    float: left;
    overflow: hidden;
    background-color: #E1E1E1;

    h1,p{
        @media screen and (max-width:767px) {
            text-align: center;
        }
    }

    .w-100{
        border-radius: 0 0 0 150px;
        position: relative;

        @media screen and (max-width: 767px) {
            border-radius: 0 0 0 100px;
        }

        .mascara{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #1F093C;
            opacity: 0.75;
            border-radius: 0 0 0 150px;
        
            @media screen and (max-width: 767px) {
                border-radius: 0 0 0 100px;
            }
        }
    }

    .rounded-pill{
        border-radius: 50px;
        font-size: 20px;
    }
}
        #section-passos{
            width: 100%;
            float: left;
            overflow: hidden;
            background-color: #E1E1E1;

            .slick-dots{
                display: flex;
                justify-content: center;
                padding-left: 0;
                padding-top: 10px;
                li{
                    list-style-type: none;
                    opacity: 0.7;

                    &.slick-active{
                        opacity: 1;
                    }
                    button{
                        outline: none;
                        color: transparent;
                        height: 3px;
                        width: 35px;
                        background: $cor1;
                        border: 2px solid $cor1;
                        padding-right: 5px;
                        padding-left: 5px;
                        margin-right: 3px;
                        margin-left: 3px;
                    }
                }
            }

            .reg-step{

                .bullet-big{
                    width: 146px;
                    height: 146px;
                    border-radius: 100%;
                    background: #85B6C8;
                    color: white;
                    font-weight: bold;
                    font-size: 22px;
                }

                .first-step{
                    position: relative;

                    img{
                        opacity: 0.50;
                        max-height: 46px;
                    }

                    &:after{
                        content:' ';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        right: -15px;
                        height: 3px;
                        background: black;

                        @media screen and (max-width:767px) {
                            background: transparent;
                        }
                    }
                }
                .middle-step{
                    position: relative;

                    img{
                        opacity: 0.75;
                        max-height: 46px;
                    }

                    &:after{
                        content:' ';
                        position: absolute;
                        bottom: 0;
                        left: -15px;
                        right: -15px;
                        height: 3px;
                        background: black;

                        @media screen and (max-width:767px) {
                            background: transparent;
                        }
                    }
                }
                .last-step{
                    position: relative;

                    img{
                        opacity: 1;
                        max-height: 46px;
                    }

                    &:after{
                        content:' ';
                        position: absolute;
                        bottom: 0;
                        left: -15px;
                        right: 50%;
                        height: 3px;
                        background: black;

                        @media screen and (max-width:767px) {
                            background: transparent;
                        }
                    }
                }
            }
        }
#section-encontrar{
    width: 100%;
    float: left;
    overflow: hidden;

    .h3{
        font-weight: bolda;
    }

    .video-thumbnail{
        padding-top: 56%;
        background-position-y: 50%;
        position: relative;
        
        .mascara{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.00);
            transition: all 0.4s ease;
        }
        .btn-play{
            right: 0;
            position: absolute;
            left: calc(50% - 50px);
            top: calc(50% - 50px);
            width: 100px;
            opacity: 0;
            transition: all 0.4s ease;
        }

        &:hover{
            .btn-play{
                opacity: 1;
            }
            .mascara{
                background-color: rgba(0,0,0,0.65);
            }
        }
    }
}
.link-bottom-banner {
    width: 100%;
    float: left;
    margin-top: -60px;

    a {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: block;
        margin: 0px auto;
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;

        &:hover {
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
}

#section-barra1-home {
    background-image: url(../img/bg1-home.png);
    background-position: top center;
    background-size: 2049px auto;
    width: 100%;
    height: 183px;
    float: left;
    padding-top: 30px;
    overflow: hidden;

    @media (max-width: 767px) {
        background: #32596c;
        padding-top: 20px;
        padding-bottom: 20px;
        height: auto;
    }

    p {
        float: left;
        color: #fff;
        font-size: 17px;
        padding-top: 10px;

        @media (max-width: 767px) {
            text-align: center;
        }
    }

    a {
        width: 100%;
        height: 46px;
        border: 1px solid #fff;
        background: transparent;
        border-radius: 23px;
        float: left;
        overflow: hidden;
        margin-top: 10px;
        color: #fff;
        font-size: 15px;
        text-transform: uppercase;
        line-height: 46px;
        text-align: center;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        text-decoration: none;
        &:hover {
            color: #32596c;
            background: #fff;
        }
    }
}

.box-estrelas {
    width: 100%;
    float: left;
    padding-top: 7px;
    padding-bottom: 7px;

    i {
        font-size: 20px;

        &.fa-star {
            color: #ffd800;
        }

        &.fa-star-o {
            color: #ededed;
        }
    }
}

.section-cursos {
    background-color: #fdfdfd;
    width: 100%;
    float: left;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 50px;

    h1 {
        color: black;
        font-weight: bold;
        font-size: 45px;
        line-height: 50px;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 30px;
            line-height: 40px;
            text-align: center;
            margin-bottom: 0px;
        }
    }

    p {
        color: $cor2;
        font-size: 34px;
        padding-bottom: 30px;
        text-align: center;

        @media (max-width: 767px) {
            font-size: 20px;
            padding-bottom: 20px;
            text-align: center;
        }
    }

    .btn-ver-todos{
        background-color: #165991;
        text-decoration: none;
        font-size: 22px;
        font-weight: bold;
    }

    .filtros{
        .reg-filtro{
            border: 1px solid #707070;
            color: #707070;
            background-color: transparent;
            transition: all 0.4s ease;
            font-weight: bold;
            cursor:pointer;
            font-size: 22px;

            @media screen and (max-width:767px) {
                width: 100%;
            }

            &:hover{
                background-color: #20203A;
                border: 1px solid #20203A;
                color: white;
            }

            &.active{
                background-color: #20203A;
                border: 1px solid #20203A;
                color: white;
            }
        }
    }

    .reg-cursos {
        background-color: #fff;
        width: 100%;
        float: left;
        margin-bottom: 30px;
        overflow: hidden;
        -webkit-box-shadow: 0px 3px 6px rgba(#444, 0.1);
        box-shadow: 0px 3px 6px rgba(#444, 0.1);
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        transition: all 0.4s;

        &:hover {
            -webkit-transform: scale(1.03);
            -ms-transform: scale(1.03);
            transform: scale(1.03);

            a {
                .img {
                    background-size: auto 120%;
                }
            }
        }

        a {
            width: 100%;
            float: left;
            overflow: hidden;

            .img {
                background-color: #333;
                background-size: auto 120%;
                background-position: center;
                width: 221px;
                height: 203px;
                float: left;
                overflow: hidden;
                -webkit-transition: all 0.4s;
                -o-transition: all 0.4s;
                transition: all 0.4s;

                @media (max-width: 500px) {
                    width: 100%;
                    height: auto;
                    padding-bottom: 70%;
                    background-size: cover;
                }
            }

            .dados {
                width: calc(100% - 221px);
                height: 203px;
                float: left;
                padding: 15px;
                overflow: hidden;

                @media (max-width: 500px) {
                    width: 100%;
                }

                h2 {
                    font-size: 20px;
                    width: 100%;
                    height: 75px;
                    float: left;
                    font-size: 18px;
                    padding-bottom: 10px;
                    color: #504a4a;
                    font-weight: bold;
                    text-transform: uppercase;
                    border-bottom: 1px solid rgba(#707070, 0.29);

                    @media (max-width: 500px) {
                        height: auto;
                    }
                }

                .box-estrelas {
                    padding-top: 7px;
                    padding-bottom: 7px;
                }

                .box-valor {
                    width: 45%;
                    float: left;
                    padding-top: 6px;
                    color: #7b7b7b;
                    line-height: 23px;
                    font-size: 16px;

                    @media screen and (max-width: 767px) {
                        width: 42%;
                    }
                    @media screen and (max-width: 350px) {
                        font-size: 15px;
                        width: 50%;
                    }
                    span {
                        font-size: 22px;
                        @media screen and (max-width: 767px) {
                            font-size: 20px;
                        }
                    }
                    .promo-val {
                        font-size: 19px;

                        @media screen and (max-width: 350px) {
                            font-size: 15px;
                        }

                        &.preco-riscado {
                            color: #da083f;
                            position: relative;

                            &::before {
                                content: " ";
                                position: absolute;
                                top: 11px;
                                left: -5px;
                                right: -5px;
                                height: 1px;
                                background: #da083f;
                                -webkit-transform: rotate(-2deg);
                                -ms-transform: rotate(-2deg);
                                transform: rotate(-2deg);
                            }
                        }
                        &.preco-promo {
                            color: #2fb644;
                        }
                    }
                }
                
                button {
                    background-color: #165991;
                    width: 55%;
                    height: 45px;
                    float: left;
                    margin-top: 5px;
                    overflow: hidden;
                    border-radius: 25px;
                    border: 0px;
                    padding: 0px 15px;
                    -webkit-transition: all 0.4s;
                    -o-transition: all 0.4s;
                    transition: all 0.4s;
                    cursor: pointer;

                    @media screen and (max-width: 767px) {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        width: 58%;
                    }
                    @media screen and (max-width: 350px) {
                        width: 50%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                    }

                    &:hover {
                        background-color: #134a77;

                        img {
                            -webkit-transform: rotate(90deg);
                            -ms-transform: rotate(90deg);
                            transform: rotate(90deg);
                        }
                    }

                    span {
                        color: #fff;
                        text-transform: uppercase;
                        float: left;
                        padding-right: 12px;
                        font-weight: bold;

                        @media screen and (max-width: 350px) {
                            font-size: 13px;
                        }
                    }

                    i{
                        margin-top: 2px;
                        font-size: 20px;
                    }

                    img {
                        width: 22px;
                        float: left;
                        -webkit-transition: all 0.4s;
                        -o-transition: all 0.4s;
                        transition: all 0.4s;

                        @media screen and (max-width: 350px) {
                            width: 15px;
                        }
                    }
                }
            }
        }
    }
}

.section-outros-cursos {
    background: rgb(213, 213, 213);
    background: -o-linear-gradient(
        top,
        rgba(213, 213, 213, 1) 0%,
        rgba(255, 255, 255, 1) 2%,
        rgba(255, 255, 255, 1) 100%
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(213, 213, 213, 1)),
        color-stop(2%, rgba(255, 255, 255, 1)),
        to(rgba(255, 255, 255, 1))
    );
    background: linear-gradient(
        180deg,
        rgba(213, 213, 213, 1) 0%,
        rgba(255, 255, 255, 1) 2%,
        rgba(255, 255, 255, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d5d5d5",endColorstr="#ffffff",GradientType=1);
    width: 100%;
    float: left;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 50px;

    .slick-dots {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: block;
        margin: 0px auto;

        li {
            display: inline;
            padding-left: 2px;
            padding-right: 2px;
            float: left;

            &.slick-active {
                button {
                    background-color: $cor2;
                }
            }

            button {
                background-color: $cor1;
                width: 20px;
                height: 20px;
                float: left;
                border: 0px;
                text-indent: -9999px;
                border-radius: 10px;
            }
        }
    }

    h1 {
        color: #000;
        font-weight: bold;
        font-size: 55px;
        line-height: 60px;

        @media screen and (max-width: 767px) {
            font-size: 45px;
        }
    }

    #slick-outros-cursos {
        width: 100%;
        float: left;

        .reg-outros-cursos {
            width: 100%;
            float: left;
            padding: 5px;
            margin-top: 20px;
            margin-bottom: 20px;
            overflow: hidden;
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;

            &:hover {
                -webkit-transform: scale(1.04);
                -ms-transform: scale(1.04);
                transform: scale(1.04);

                a {
                    .img {
                        background-size: auto 120%;
                    }
                }
            }

            a {
                width: 100%;
                float: left;
                overflow: hidden;

                .img {
                    background-size: auto 100%;
                    background-position: center;
                    width: 100%;
                    float: left;
                    overflow: hidden;
                    -webkit-transition: all 0.4s;
                    -o-transition: all 0.4s;
                    transition: all 0.4s;

                    .mascara {
                        background-color: rgba(#000, 0.6);
                        width: 100%;
                        height: 308px;
                        float: left;
                        overflow: hidden;
                        display: table;
                        position: relative;

                        h2 {
                            font-size: 23px;
                            font-weight: bold;
                            color: #fff;
                            text-align: center;
                            width: 100%;
                            padding-left: 15px;
                            padding-right: 15px;
                            display: table-cell;
                            vertical-align: middle;
                            text-transform: uppercase;
                        }
                    }
                }

                .dados {
                    background-color: #f0f0f0;
                    width: 100%;
                    float: left;
                    padding: 15px;
                    overflow: hidden;

                    .box-estrelas {
                        i {
                            &.fa-star {
                            }
                            &.fa-star-o {
                                color: #e2e2e2;
                            }
                        }
                    }

                    .box-valor {
                        width: 48%;
                        float: left;
                        padding-top: 4px;
                        color: #7b7b7b;
                        line-height: 18px;
                        font-size: 15px;

                        span {
                            font-size: 16px;

                            &.val-promocional {
                                position: relative;
                                color: #da083f;
                                &:after {
                                    position: absolute;
                                    content: " ";
                                    top: 50%;
                                    right: -10px;
                                    left: -10px;
                                    height: 1px;
                                    background: #da083f;
                                    -webkit-transform: rotate(-3deg);
                                    -ms-transform: rotate(-3deg);
                                    transform: rotate(-3deg);
                                }
                            }

                            &.val-promo-verde {
                                color: #2fb644;
                            }
                        }
                    }

                    button {
                        background-color: #258d35;
                        width: 52%;
                        height: 40px;
                        float: left;
                        margin-top: 2px;
                        overflow: hidden;
                        border-radius: 25px;
                        border: 0px;
                        padding: 0px 10px;
                        -webkit-transition: all 0.4s;
                        -o-transition: all 0.4s;
                        transition: all 0.4s;
                        cursor: pointer;
                        @media screen and (max-width: 767px) {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                        }

                        &:hover {
                            background-color: #2fb644;

                            img {
                                -webkit-transform: rotate(90deg);
                                -ms-transform: rotate(90deg);
                                transform: rotate(90deg);
                            }
                        }

                        span {
                            color: #fff;
                            text-transform: uppercase;
                            float: left;
                            font-size: 13px;
                            padding-right: 9px;
                        }

                        img {
                            width: 17px;
                            float: left;
                            -webkit-transition: all 0.4s;
                            -o-transition: all 0.4s;
                            transition: all 0.4s;
                        }
                    }
                }
            }
        }
    }
}

.section-mensagem {
    background-image: url(../img/imagem.png);
    background-size: cover;
    background-position: center;
    width: 100%;
    float: left;
    overflow: hidden;

    .mascara {
        background-color: rgba($cor2, 0.58);
        width: 100%;
        float: left;
        overflow: hidden;
        padding-top: 100px;
        padding-bottom: 100px;

        @media screen and (max-width: 767px) {
            padding-top: 2em;
            padding-bottom: 2em;
        }

        h1 {
            width: 100%;
            max-width: 750px;
            display: block;
            margin: 0px auto;
            color: #fff;
            font-size: 50px;
            font-weight: bold;
            text-align: center;
            padding-bottom: 60px;
            text-shadow: 1px 1px 1px rgba($cor2, 0.5);

            @media screen and (max-width: 767px) {
                font-size: 2em;
                padding-bottom: 25px;
            }
        }

        a {
            width: 100%;
            height: 70px;
            float: left;
            border: 0px;
            border-radius: 35px;
            font-size: 23px;
            padding-top: 20px;
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;

            @media screen and (max-width: 767px) {
                font-size: 18px;
                padding-top: 22px;
            }
            @media screen and (max-width: 340px) {
                font-size: 16px;
                padding-top: 0;
                padding-bottom: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }

            &.btn1 {
                background-color: rgba($cor1, 0.84);

                @media screen and (max-width: 767px) {
                    margin-bottom: 0.5em;
                }

                &:hover {
                    background-color: $cor2;
                }
            }

            &.btn2 {
                background-color: rgba($cor1, 0.84);

                @media screen and (max-width: 767px) {
                    margin-bottom: 0.5em;
                }

                &:hover {
                    background-color: $cor2;
                }
            }
        }
    }
}

#section-carousel-depoimentos {
    width: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;

    a {
        color: #000;
        text-decoration: none;
    }

    .slick-current {
        &:focus {
            outline: 0;
        }
    }
    .header-vermelho {
        color: rgba(64, 64, 64, 0.85);
        font-size: 2em;
        font-weight: bold;
        text-align: center;
        @media screen and (max-width: 992px) {
            text-align: center;
        }
    }
    .header-noticia {
        color: $cor1 !important;
    }

    .link-ver-todos {
        text-align: right;
        text-align: center;
        margin-top: 1em;
        @media screen and (max-width: 992px) {
            text-align: center;
            margin-bottom: 1.5em;
        }
        a {
            -webkit-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;
            text-decoration: none;
            color: rgba(64, 64, 64, 0.85);
            padding: 1em;
            background: transparent;

            border: 1px solid transparent;
            &:hover {
                border: 1px solid rgba(64, 64, 64, 0.85);
                border-radius: 5px;
            }
        }
    }
    .custom-arrow {
        position: absolute;
        bottom: 40%;
        cursor: pointer;
        @media screen and (min-width: 923px) {
            img {
                max-width: 80%;
            }
        }
        @media screen and (max-width: 922px) {
            -webkit-transform: scale(0.5);
            -ms-transform: scale(0.5);
            transform: scale(0.5);
            display: none !important;
        }
    }
    .next-c-slide-2 {
        right: 9%;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        @media screen and (max-width: 1500px) {
            right: 0;
        }
    }
    .prev-c-slide-2 {
        left: 9%;
        -webkit-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        @media screen and (max-width: 1500px) {
            left: 0;
        }
    }
    .slick-dots {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: block;
        margin: 0px auto;
        -webkit-padding-start: 0;
        padding-inline-start: 0;

        li {
            display: inline;
            padding-left: 2px;
            padding-right: 2px;
            float: left;

            &.slick-active {
                button {
                    background-color: $cor2;
                }
            }

            button {
                background-color: $cor1;
                width: 20px;
                height: 20px;
                float: left;
                border: 0px;
                text-indent: -9999px;
                border-radius: 10px;
            }
        }
    }
    #carousel-depoimentos {
        .card-depoimento {
            a {
                &:focus {
                    outline: 0;
                }
            }

            &:hover {
                .quadrado-texto {
                    border-radius: 50px 0px 50px 0px;
                }

                .foto-redonda {
                    .foto-bg {
                        height: 165px !important;
                        width: 165px !important;
                    }
                }
            }

            .quadrado-texto {
                text-align: center;
                background: white;
                border: 1px solid rgba(163, 163, 163, 0.5);
                height: 280px;
                width: 320px;
                border-radius: 50px 0px 15px 0px;
                margin: 0 auto;
                padding: 2em 1.5em;
                position: relative;
                -webkit-transition: all 0.4s ease;
                -o-transition: all 0.4s ease;
                transition: all 0.4s ease;
                @media screen and (max-width: 767px) {
                    min-height: 250px;
                    width: 250px;
                    height: unset;
                }
                @media screen and (max-width: 350px) {
                    min-height: 220px;
                    width: 220px;
                    height: unset;
                }
                @media screen and (max-width: 320px) {
                    min-width: 180px !important;
                    height: 180px !important;
                    height: unset;
                }
                .quote {
                    position: relative;

                    .texto-quadrado {
                        overflow: hidden;
                        max-height: 200px;
                        position: relative;
                        font-size: 16px;
                        color: #746464;

                        @media screen and (max-width: 767px) {
                            max-height: unset;
                        }

                        @media screen and (max-width: 340px) {
                            max-height: 150px;
                        }
                    }

                    &::before {
                        content: "''";
                        font-size: 3em;
                        color: #746464;
                        position: absolute;
                        top: -30px;
                        left: 5px;
                    }
                    &::after {
                        content: "''";
                        font-size: 3em;
                        color: #746464;
                        position: absolute;
                        right: -5px;
                        bottom: -50px;
                    }
                }

                .foto-redonda {
                    position: absolute;
                    bottom: -110px;
                    right: 0;
                    left: 0;
                    width: 100%;

                    @media screen and (max-width: 767px) {
                        bottom: -160px;
                    }

                    .foto-bg {
                        -webkit-transition: all 0.4s ease;
                        -o-transition: all 0.4s ease;
                        transition: all 0.4s ease;
                        margin: 0 auto;
                        border-radius: 100%;
                        height: 160px;
                        width: 160px;
                    }
                }
            }
            .texto-nome-titulo {
                padding: 0.75em 1em;
                padding-top: 7em;
                text-align: center;

                @media screen and (max-width: 767px) {
                    padding-top: 11em;
                }

                h1 {
                    font-size: 1.2em;
                    color: $cor1;
                    font-weight: bold;
                    max-width: 80%;
                    margin: 0 auto;
                    padding-bottom: 0.3em;
                }

                p {
                    margin: 0 auto;
                    text-align: center;
                    max-width: 150px;
                }
            }
        }
    }
}

#section-map {
    width: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    background: #e3ce0e;

    --default-blue-stroke: rgba(255, 255, 255, 0.35);
    --default-grey-black-fill: #42b8b3;
    --default-strok: #1d1d19;

    .col-mapa{
        position: relative;

        .col-texto{
            position: absolute;
            bottom: 15%;
            left: 0;

            h1{
                font-size: 50px;
                font-weight: bold;
                line-height: 45px;
            }
        }
    }

    .area-info {
        background-color: #1a1a1a;
        color: white;
        padding: 20px;
        flex-grow: 1;

        .separador {
            width: 100%;
            height: 1px;
            position: relative;

            &:after {
                content: " ";
                position: absolute;
                top: 0;
                left: 2.5%;
                right: 2.5%;
                bottom: 0;
                background-color: white;
            }
        }

        ul {
            list-style: none;
            padding-left: 16px;
            li {
                list-style: none;
                position: relative;

                &:before {
                    content: " ";
                    position: absolute;
                    top: calc(50% - 4px);
                    left: -16px;
                    height: 8px;
                    width: 8px;
                    background-color: #ffc400;
                }
                a {
                    color: white;
                    text-decoration: none;
                }
            }
        }

        .loader {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 200px;
            height: 200px;
            background: transparent;

            div {
                width: 20px;
                height: 20px;
                background: #8385aa;
                border-radius: 50%;
                margin: 3px;
                animation: loader 0.6s infinite alternate;

                &:nth-child(2) {
                    animation-delay: 0.2s;
                }
                &:nth-child(3) {
                    animation-delay: 0.4s;
                }
            }
        }
        @keyframes loader {
            to {
                opacity: 0.1;
                transform: translate(0, -20px);
                background: pink;
            }
        }
    }

    #mapa-svg-area {
        transform: scale(1.4);
    }

    svg text {
        fill: transparent;
        font-family: monospace;
        font-weight: bolder;
    }
    .mapa-svg-estados {
        fill: #42b8b3;
        -webkit-transition: 0.8s ease;
        -moz-transition: 0.8s ease;
        -ms-transition: 0.8s ease;
        -o-transition: 0.8s ease;
        transition: 0.8s ease;
        stroke-dasharray: 180%;
        stroke-dashoffset: -120%;
        // stroke-width: 2px;
        stroke: var(--default-strok);
        position: relative;
        z-index: 20;

        cursor: pointer;
        stroke: var(--default-blue-stroke);
        fill: var(--default-grey-black-fill);
        stroke-dashoffset: 0%;
        -webkit-transition: 0.8s ease;
        -moz-transition: 0.8s ease;
        -ms-transition: 0.8s ease;
        -o-transition: 0.8s ease;

        circle {
            transition: all 0.4s ease;
            r: 5 !important;
        }

        &:hover {
            cursor: pointer;
            fill: #2b371e;
            z-index: 22;

            text {
                fill: var(--default-strok);
            }

            circle {
                r: 12 !important;
            }
        }
    }
    .mapa-svg-estados text {
        stroke: none;
    }

    .active-uf,.mapa-svg-estados-active {
        cursor: pointer;
        stroke: var(--default-blue-stroke);
        fill: #2b371e;
        stroke-dashoffset: 0%;
        -webkit-transition: 0.8s ease;
        -moz-transition: 0.8s ease;
        -ms-transition: 0.8s ease;
        -o-transition: 0.8s ease;

        text {
            fill: var(--default-strok);
        }

        circle {
            r: 12 !important;
        }
    }
    .mapa-svg-estados-active text {
        stroke: none;
    }

    .pointer-svg-map {
        fill: var(--default-blue-stroke);
        animation-direction: normal;
        animation-delay: 0.3s;
    }

    .inactive-map-svg {
        opacity: 0.5;
        stroke: none !important;
    }

    .inactive-map-svg:hover {
        stroke-dashoffset: -120%;
        cursor: inherit;
        fill: var(--default-grey-black-fill);
    }

    @keyframes pointer {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    #sotreq-select {
        display: flex;
        flex-direction: column;
    }

    .class-select {
        outline: none;
        height: 40px;
        width: 250px;
        padding: 10px 18px;
        border-radius: 32px;
        color: #3c3c3c;
        margin-bottom: 2rem;
    }
}
